import React, { useRef, useEffect, useState } from 'react';
import { Button, Popover, Select, Tooltip, Modal } from 'antd';
import dayjs from 'dayjs';
import { DayPicker, DateRange } from 'react-day-picker';
import 'react-day-picker/style.css';
import { ArrowRightOutlined } from '@ant-design/icons';
const { Option } = Select;

interface IRangePickerWithTime {
  checkin: string;
  checkout: string;
  onChange: any;
  isCity: boolean;
  isHome?: boolean;
  isLanding?: boolean;
  deviceType: string;
  isParkingAt?: boolean;
  isSearch?: boolean;
}

const timeOptions = [
  '00:00',
  '00:30',
  '01:00',
  '01:30',
  '02:00',
  '02:30',
  '03:00',
  '03:30',
  '04:00',
  '04:30',
  '05:00',
  '05:30',
  '06:00',
  '06:30',
  '07:00',
  '07:30',
  '08:00',
  '08:30',
  '09:00',
  '09:30',
  '10:00',
  '10:30',
  '11:00',
  '11:30',
  '12:00',
  '12:30',
  '13:00',
  '13:30',
  '14:00',
  '14:30',
  '15:00',
  '15:30',
  '16:00',
  '16:30',
  '17:00',
  '17:30',
  '18:00',
  '18:30',
  '19:00',
  '19:30',
  '20:00',
  '20:30',
  '21:00',
  '21:30',
  '22:00',
  '22:30',
  '23:00',
  '23:30',
];

function RangePickerWithTime({
  checkin,
  checkout,
  onChange,
  deviceType,
  isCity,
  isHome,
  isLanding,
  isParkingAt,
  isSearch,
}: IRangePickerWithTime) {
  const [selectedDates, setSelectedDates] = useState<DateRange | null>({
    from: checkin ? dayjs(checkin).toDate() : null,
    to: checkout ? dayjs(checkout).toDate() : null,
  });
  const [selectedTimes, setSelectedTimes] = useState<{
    from?: string;
    to?: string;
  }>({
    from: checkin ? dayjs(checkin).format('HH:mm') : undefined,
    to: checkout ? dayjs(checkout).format('HH:mm') : undefined,
  });
  const [tempDates, setTempDates] = useState<DateRange | null>(selectedDates);
  const [tempTimes, setTempTimes] = useState<{
    from?: string;
    to?: string;
  }>({
    from: checkin ? dayjs(checkin).format('HH:mm') : undefined,
    to: checkout ? dayjs(checkout).format('HH:mm') : undefined,
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [visibleMonth, setVisibleMonth] = useState<Date>(
    selectedDates?.from || new Date()
  );

  const handleDayClick = (date: Date) => {
    if (!tempDates?.from) {
      setTempDates({ from: date, to: date });
    } else if (!tempDates?.to || tempDates?.from === tempDates?.to) {
      const sortedDates = [tempDates.from, date].sort(
        (a, b) => a.getTime() - b.getTime()
      );
      setTempDates({ from: sortedDates[0], to: sortedDates[1] });
    } else {
      setTempDates({ from: date, to: date });
    }
  };

  const handleTimeChange = (value: string, type: 'from' | 'to') => {
    setTempTimes({
      ...tempTimes,
      [type]: value,
    });
  };

  const applyDateTime = () => {
    setSelectedDates(tempDates);
    setSelectedTimes(tempTimes);
    const startTime = tempTimes?.from?.split(':');
    const startDate = dayjs(tempDates?.from)
      .set('hour', parseInt(startTime[0]))
      .set('minute', parseInt(startTime[1]));
    const endTime = tempTimes?.to?.split(':');
    const endDate = dayjs(tempDates?.to)
      .set('hour', parseInt(endTime[0]))
      .set('minute', parseInt(endTime[1]));
    const formattedDates = [startDate.format(), endDate.format()];
    onChange(formattedDates);
  };

  const resetToDefaults = () => {
    const currentTime = dayjs();
    const defaultCheckinTime = currentTime
      .add(30, 'minute')
      .startOf('minute')
      .add(30 - (currentTime.minute() % 30), 'minute');
    const defaultCheckoutTime = isCity
      ? defaultCheckinTime.add(3, 'hour')
      : defaultCheckinTime;

    setSelectedDates({ from: null, to: null });
    setSelectedTimes({
      from: defaultCheckinTime.format('HH:mm'),
      to: defaultCheckoutTime.format('HH:mm'),
    });
    setTempDates({ from: null, to: null });
    setTempTimes({
      from: defaultCheckinTime.format('HH:mm'),
      to: defaultCheckoutTime.format('HH:mm'),
    });
  };

  // update selected dates and times when checkin and checkout change
  useEffect(() => {
    const currentTime = dayjs();

    const defaultCheckinTime = currentTime
      .add(30, 'minute')
      .startOf('minute')
      .add(30 - (currentTime.minute() % 30), 'minute');
    const defaultCheckoutTime = isCity
      ? defaultCheckinTime.add(3, 'hour')
      : defaultCheckinTime;

    setSelectedDates({
      from: checkin ? dayjs(checkin).toDate() : null,
      to: checkout ? dayjs(checkout).toDate() : null,
    });
    setSelectedTimes({
      from: checkin
        ? dayjs(checkin).format('HH:mm')
        : defaultCheckinTime.format('HH:mm'),
      to: checkout
        ? dayjs(checkout).format('HH:mm')
        : defaultCheckoutTime.format('HH:mm'),
    });
    setTempDates({
      from: checkin ? dayjs(checkin).toDate() : null,
      to: checkout ? dayjs(checkout).toDate() : null,
    });
    setTempTimes({
      from: checkin
        ? dayjs(checkin).format('HH:mm')
        : defaultCheckinTime.format('HH:mm'),
      to: checkout
        ? dayjs(checkout).format('HH:mm')
        : defaultCheckoutTime.format('HH:mm'),
    });
  }, [checkin, checkout, isCity]);

  useEffect(() => {
    if (selectedDates?.from) {
      setVisibleMonth(selectedDates.from);
    }
  }, [selectedDates]);

  return (
    <div className={`w-full flex flex-row gap-x-2 ${isParkingAt && 'mb-4'} `}>
      {deviceType === 'mobile' ? (
        <>
          <div
            className={`${
              isHome
                ? 'w-full flex flex-row gap-x-2 mb-4 md:mb-0'
                : isParkingAt
                ? 'w-full flex flex-row justify-between items-center gap-y-2.5 gap-x-2'
                : 'w-full flex flex-row gap-y-2.5 gap-x-2'
            } `}
          >
            <div className="w-full">
              {(isParkingAt || isSearch) && (
                <p className="md:hidden font-bold">
                  {isParkingAt ? 'Parking Check-In' : 'Start Date'}
                </p>
              )}
              <Button
                onClick={() => setIsModalOpen(true)}
                className={`h-10 w-full cursor-pointer bg-white text-left ${
                  isHome
                    ? 'border p-2'
                    : isLanding
                    ? 'rounded-full'
                    : isSearch && 'border-none px-5 md:px-3 md:py-3'
                }  `}
              >
                <p className="text-xs">
                  {selectedDates?.from ? (
                    `${dayjs(selectedDates.from).format('MM/DD/YYYY')} ${
                      selectedTimes.from
                        ? dayjs(selectedTimes.from, 'HH:mm').format('hh:mm A')
                        : ''
                    }`
                  ) : (
                    <p className="text-cityList/80 pl-1">Start Date</p>
                  )}
                </p>
              </Button>
            </div>
            {isParkingAt && <ArrowRightOutlined className="md:hidden" />}
            <div className="w-full">
              {(isParkingAt || isSearch) && (
                <p className="md:hidden font-bold">
                  {isParkingAt ? 'Parking Check-Out' : 'End Date'}
                </p>
              )}
              <Button
                onClick={() => setIsModalOpen(true)}
                className={`h-10 w-full cursor-pointer bg-white text-left ${
                  isHome
                    ? 'border p-2'
                    : isLanding
                    ? 'rounded-full'
                    : isSearch && 'border-none px-5 md:px-3 md:py-3'
                }`}
              >
                <p className="text-xs">
                  {selectedDates?.from ? (
                    `${
                      selectedDates?.to
                        ? `${dayjs(selectedDates.to).format('MM/DD/YYYY')} ${
                            selectedTimes.to
                              ? dayjs(selectedTimes.to, 'HH:mm').format(
                                  'hh:mm A'
                                )
                              : ''
                          }`
                        : ''
                    }`
                  ) : (
                    <p className="text-cityList/80 pl-1">End Date</p>
                  )}
                </p>
              </Button>
            </div>
            <Modal
              visible={isModalOpen}
              centered
              onCancel={() => {
                setIsModalOpen(false);
                setTempDates(selectedDates);
                setTempTimes(selectedTimes);
              }}
              footer={false}
              bodyStyle={{ backgroundColor: 'white' }}
            >
              <div className="flex flex-col gap-y-2 p-2">
                <p className="text-xs">
                  Select a single date or a range of several days– however long
                  you need a spot!
                </p>
                <p className="text-xs">
                  Make sure to select both the date and time before clicking the
                  submit button.
                </p>
                <div className="flex flex-col gap-x-20">
                  <DayPicker
                    mode="range"
                    selected={tempDates?.from ? tempDates : undefined}
                    onDayClick={handleDayClick}
                    disabled={{ before: new Date() }}
                    key={tempDates?.from?.getTime() || tempDates?.to?.getTime()}
                    month={visibleMonth}
                    onMonthChange={setVisibleMonth}
                    className="mx-auto"
                  />
                  <div className="flex flex-col gap-y-2 bg-transparent">
                    <p>
                      <strong>Start Date: </strong>

                      {tempDates?.from
                        ? `${dayjs(tempDates.from).format('MM/DD/YYYY')}`
                        : 'Select Start Date'}
                    </p>
                    <Select
                      className="w-full animate-fadeit py-2 px-3 border rounded-md"
                      placeholder="Start Time"
                      onChange={(value) => handleTimeChange(value, 'from')}
                      value={tempTimes.from}
                    >
                      {timeOptions.map((time) => (
                        <Option key={time} value={time}>
                          {dayjs(time, 'HH:mm').format('h:mm A')}
                        </Option>
                      ))}
                    </Select>
                    <p>
                      <strong>End Date: </strong>
                      {tempDates?.from
                        ? `${dayjs(tempDates.to).format('MM/DD/YYYY')}`
                        : 'Select End Date'}
                    </p>
                    <Select
                      className="w-full animate-fadeit py-2 px-3 border rounded-md"
                      placeholder="End Time"
                      onChange={(value) => handleTimeChange(value, 'to')}
                      value={tempTimes.to}
                    >
                      {timeOptions.map((time) => (
                        <Option key={time} value={time}>
                          {dayjs(time, 'HH:mm').format('h:mm A')}
                        </Option>
                      ))}
                    </Select>
                    <div className="flex flex-row gap-x-2 w-full mt-2">
                      <Button
                        type="primary"
                        className="w-full h-12"
                        onClick={() => {
                          setIsModalOpen(false);
                          applyDateTime();
                        }}
                      >
                        Submit
                      </Button>
                      {isHome && (
                        <Button
                          type="primary"
                          className="w-full h-12 bg-white text-primary border-primary"
                          onClick={(e) => {
                            e.stopPropagation();
                            resetToDefaults();
                          }}
                        >
                          Clear
                        </Button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </Modal>
          </div>
        </>
      ) : (
        <Popover
          placement="bottom"
          trigger={['click']}
          visible={isModalOpen}
          onVisibleChange={(visible) => {
            if (!visible) {
              setTempDates(selectedDates);
              setTempTimes(selectedTimes);
            }
            setIsModalOpen(visible);
          }}
          content={
            <div className="flex flex-col gap-y-2 p-4">
              <p>
                Select a single date or a range of several days– however long
                you need a spot!
              </p>
              <p>
                Make sure to select both the date and time before clicking the
                submit button.
              </p>
              <div className="flex flex-row gap-x-20">
                <DayPicker
                  mode="range"
                  selected={tempDates?.from ? tempDates : undefined}
                  onDayClick={handleDayClick}
                  disabled={{ before: new Date() }}
                  key={tempDates?.from?.getTime() || tempDates?.to?.getTime()}
                  month={visibleMonth}
                  onMonthChange={setVisibleMonth}
                />
                <div className="flex flex-col gap-y-2">
                  <strong>Start Date</strong>
                  <p>
                    {tempDates?.from
                      ? `${dayjs(tempDates.from).format('MM/DD/YYYY')}`
                      : 'Select Start Date'}
                  </p>
                  <Select
                    className="w-full animate-fadeit py-2 px-3 border rounded-md mb-4"
                    placeholder="Start Time"
                    onChange={(value) => handleTimeChange(value, 'from')}
                    value={tempTimes.from}
                  >
                    {timeOptions.map((time) => (
                      <Option key={time} value={time}>
                        {dayjs(time, 'HH:mm').format('h:mm A')}
                      </Option>
                    ))}
                  </Select>
                  <strong>End Date</strong>
                  <p>
                    {tempDates?.from
                      ? `${dayjs(tempDates.to).format('MM/DD/YYYY')}`
                      : 'Select End Date'}
                  </p>
                  <Select
                    className="w-full animate-fadeit py-2 px-3 border rounded-md mb-4"
                    placeholder="End Time"
                    onChange={(value) => handleTimeChange(value, 'to')}
                    value={tempTimes.to}
                  >
                    {timeOptions.map((time) => (
                      <Option key={time} value={time}>
                        {dayjs(time, 'HH:mm').format('h:mm A')}
                      </Option>
                    ))}
                  </Select>
                  <div className="flex flex-row gap-x-2">
                    <Button
                      type="primary"
                      onClick={() => {
                        applyDateTime();
                        setIsModalOpen(false);
                      }}
                    >
                      Submit
                    </Button>
                    {isHome && (
                      <Button
                        type="primary"
                        className="bg-white text-primary border-primary"
                        onClick={(e) => {
                          e.stopPropagation();
                          resetToDefaults();
                        }}
                      >
                        Clear
                      </Button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          }
        >
          <div className="w-full flex flex-row justify-between md:gap-x-2 mb-4 md:mb-0">
            <Tooltip
              title="When will you drop off your vehicle?"
              overlayClassName="search-tooltip"
            >
              <p
                className={`h-10 ${
                  isHome
                    ? 'border rounded-md py-2.5 px-2 md:px-3'
                    : isParkingAt
                    ? 'md:border rounded-md py-2.5 px-2 md:px-3'
                    : 'p-3'
                } w-full cursor-pointer bg-white flex items-center justify-center whitespace-nowrap overflow-hidden text-ellipsis`}
              >
                {selectedDates?.from ? (
                  `${dayjs(selectedDates.from).format('MM/DD/YYYY')} ${
                    selectedTimes.from
                      ? dayjs(selectedTimes.from, 'HH:mm').format('hh:mm A')
                      : ''
                  }`
                ) : (
                  <span className="text-cityList/80">Start Date</span>
                )}
              </p>
            </Tooltip>

            <Tooltip
              title="When will you pick up your vehicle?"
              overlayClassName="search-tooltip"
            >
              <p
                className={`h-10 
        ${
          isHome
            ? 'border rounded-md py-2.5 px-2 md:px-3'
            : isParkingAt
            ? 'md:border rounded-md py-2.5 px-2 md:px-3'
            : 'p-3'
        } w-full cursor-pointer bg-white flex items-center justify-center whitespace-nowrap overflow-hidden text-ellipsis`}
              >
                {selectedDates?.from ? (
                  `${
                    selectedDates?.to
                      ? `${dayjs(selectedDates.to).format('MM/DD/YYYY')} ${
                          selectedTimes.to
                            ? dayjs(selectedTimes.to, 'HH:mm').format('hh:mm A')
                            : ''
                        }`
                      : ''
                  }`
                ) : (
                  <span className="text-cityList/80">End Date</span>
                )}
              </p>
            </Tooltip>
          </div>
        </Popover>
      )}
    </div>
  );
}

export default RangePickerWithTime;
